import styled from "styled-components/macro";
import { Theme } from "@material-ui/core/styles";

export const DivManageUserLayoutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivFormContainer = styled.div<{ theme: Theme }>`
  padding: 6rem 6rem 4rem;
  width: 60rem;
`;
