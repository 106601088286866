import { Action } from "react-sweet-state";
import dcopy from "deep-copy";
import { GlobalState } from "../types";
import httpClient from "../../../utils/http-client";

const clearAuthenticatedUser =
  (): Action<GlobalState> =>
  ({ getState, setState }) => {
    const prevState = getState();

    sessionStorage.clear();
    httpClient.setAccessToken("");

    setState(dcopy({ ...prevState, user: undefined }));
  };

export default clearAuthenticatedUser;
