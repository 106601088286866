import React, { useMemo } from "react";
import { Box, Grid } from "@material-ui/core";
import Anchor from "../../Anchor";
import config from "../../../config";
import Text from "../../Text";
import imgLogo from "../../../assets/logo.png";
import iconFb from "../../../assets/icon-fb.png";
import iconLi from "../../../assets/icon-li.png";
import { ImgFooterLogo, ImgFooterSocialIcon } from "../styles";

interface Props {}

const IconsItem: React.FC<Props> = (props: Props): JSX.Element => {
  const { homeUrl, linkedInUrl, facebookUrl } = useMemo(() => {
    return config.mosaic;
  }, []);

  return (
    <Grid item container direction={"column"} alignItems={"center"}>
      <Anchor noStyle href={homeUrl} target={"_blank"} rel={"noreferrer"}>
        <ImgFooterLogo src={imgLogo} alt="Mosaic Family Wealth" />
      </Anchor>
      <Box mt={2} mb={3}>
        <Anchor noStyle href={facebookUrl} target={"_blank"} rel={"noreferrer"}>
          <ImgFooterSocialIcon
            mr={0.25}
            src={iconFb}
            alt="View our facebook page"
          />
        </Anchor>
        <Anchor noStyle href={linkedInUrl} target={"_blank"} rel={"noreferrer"}>
          <ImgFooterSocialIcon
            ml={0.25}
            src={iconLi}
            alt="View our LinkedIn profile"
          />
        </Anchor>
      </Box>
      <Text font={"DIN"} lineHeight={1.5}>
        © 2021 Mosaic Family Wealth
      </Text>
    </Grid>
  );
};

export default IconsItem;
