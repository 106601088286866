import { Component, ErrorInfo } from "react";
import { ErrorBoundaryState, ErrorFallbackComponent } from "./types";

interface Props {
  fallback: ErrorFallbackComponent;
  children?: any;
}

export default class ErrorBoundary extends Component<
  Props,
  ErrorBoundaryState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      fallback: props.fallback,
    };
  }

  public static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ ...this.state, error, info });
  }

  public render() {
    const Fallback = this.state.fallback;

    if (this.state.hasError) {
      return <Fallback error={this.state.error!} info={this.state.info!} />;
    }

    return this.props.children;
  }
}
