import styled, { css } from "styled-components/macro";
import bgTile from "../../assets/bg-tile.png";

const TiledBg = css`
  background-image: url("${bgTile}");
  background-repeat: repeat;
`;

const SolidBg = css`
  background-color: var(--darkGray);
`;

export const DivMainContent = styled.div<{ isTiled?: boolean }>`
  ${(props) => (props.isTiled ? TiledBg : SolidBg)}
  min-height: calc(100vh - ${(props) => props.theme.footer.height}rem);
  min-width: 100%;
  padding: calc(var(--grid-unit) * 6) 0 calc(var(--grid-unit) * 8);
`;
