import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import ManageUserLayout from "src/layouts/UserManagementLayout";
import Input from "../../../components/Input";
import { VerifyCodeForm, VerifyCodeLocationState } from "./types";
import { useDefaultForm } from "../../../hooks/use-default-form";
import { verifyCodeFormSchema } from "./schema";
import Form from "../../../components/Form";
import FieldSet from "../../../components/Form/FieldSet";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import Text from "../../../components/Text";
import { Box } from "@material-ui/core";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { useHistory, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";
import {
  CognitoChallenge,
  IS_TRUSTED_DEVICE,
  ROUTES,
} from "../../../domain/frontend";
import { defaultErrorMessage } from "../../../domain/frontend";
import { getErrorMessage } from "./utils";
import { useGlobalState } from "../../../state/global";

interface Props {}

const VerifyCode: FC<Props> = (props: Props): JSX.Element => {
  const history = useHistory();
  const [, { setAuthenticatedUser }] = useGlobalState();
  const { state } = useLocation<VerifyCodeLocationState>();
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useDefaultForm<VerifyCodeForm>(verifyCodeFormSchema);
  const [formError, setFormError] = useState("");
  const [mobile, setMobile] = useState("");

  const isDeviceTrusted = useMemo(() => {
    return localStorage.getItem(IS_TRUSTED_DEVICE) === "true";
  }, []);

  const { tempUser, tempUsername, isUnconfirmedUser, canRememberDevice } =
    useMemo(() => {
      return {
        tempUser: state?.user,
        tempUsername: state?.username,
        isUnconfirmedUser: state?.isUnconfirmedUser ?? false,
        canRememberDevice: state?.canRememberDevice ?? false,
      };
    }, [state]);

  const verifyCode = handleSubmit(async ({ code }) => {
    try {
      setFormError("");
      await Auth.confirmSignIn(tempUser, code, CognitoChallenge.SMS);

      if (canRememberDevice) {
        await Auth.rememberDevice();
      }

      const { attributes } = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken().getJwtToken();

      setAuthenticatedUser(attributes, accessToken);

      if (!isDeviceTrusted && canRememberDevice) {
        localStorage.setItem(IS_TRUSTED_DEVICE, "true");
      }

      history.push(ROUTES.dashboard);
    } catch (error) {
      setFormError(getErrorMessage(error));
    }
  });

  const reSendCode = useCallback(async (usernameFromSignup: string) => {
    setFormError("");
    try {
      const {
        CodeDeliveryDetails: { Destination },
      } = await Auth.resendSignUp(usernameFromSignup);

      setMobile(Destination);
    } catch (error) {
      setFormError(defaultErrorMessage);
    }
  }, []);

  useEffect(() => {
    if (!tempUser && !tempUsername) {
      history.push(ROUTES.home);
    }

    if (isUnconfirmedUser && tempUsername) {
      reSendCode(tempUsername);
    } else {
      const challengeParams = (tempUser as any).challengeParam;
      setMobile(challengeParams?.CODE_DELIVERY_DESTINATION);
    }
  }, [history, isUnconfirmedUser, reSendCode, tempUser, tempUsername]);

  return (
    <ManageUserLayout heading={"Verify code"}>
      <Box mb={5}>
        <Text size={1.7}>
          A verification code was sent to {mobile}. Please enter the code below
          to confirm your identity.
        </Text>
      </Box>
      <Form
        onSubmit={verifyCode}
        isLoading={isSubmitting}
        error={formError}
        justifyButtons={"flex-end"}
        buttons={[
          {
            type: "submit",
            component: ButtonPrimary,
            text: "Submit",
          },
        ]}
      >
        <FieldSet>
          <FieldWrapper name={"Code"} error={errors.code} required>
            <Input autoFocus {...register("code")} type={"number"} />
          </FieldWrapper>
        </FieldSet>
      </Form>
    </ManageUserLayout>
  );
};

export default VerifyCode;
