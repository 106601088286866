import { ChangePasswordErrors } from "./types";
import { defaultErrorMessage } from "../../../domain/frontend/constants/copy";

export function getErrorMessage(error: Error): string {
  if (error.name === ChangePasswordErrors.INVALID_PASSWORD) {
    return "The existing password you entered is incorrect.";
  } else {
    return defaultErrorMessage;
  }
}
