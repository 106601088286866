import { AuthErrors } from "./types";
import { defaultErrorMessage } from "../../../domain/frontend/constants/copy";

export function getErrorMessage(error: Error): string {
  switch (error.name) {
    case AuthErrors.UNAUTHORIZED:
      return "Invalid username or password.";
    case AuthErrors.NOT_FOUND:
      return "It doesn't look like you're registered.";
    case AuthErrors.PASSWORD_RESET:
      return "Your password must be reset - click 'Forgotten password'.";
    default:
      return defaultErrorMessage;
  }
}
