import React, { FC, useState } from "react";
import Form from "src/components/Form";
import UserManagementLayout from "../../../layouts/UserManagementLayout";
import FieldSet from "../../../components/Form/FieldSet";
import FieldWrapper from "../../../components/Form/FieldWrapper";
import { useDefaultForm } from "../../../hooks/use-default-form";
import { forgotPassSendCodeFormSchema } from "./schema";
import { ForgotPassSendCodeForm } from "./types";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import Input from "../../../components/Input";
import { Auth } from "aws-amplify";
import { defaultErrorMessage } from "../../../domain/frontend/constants/copy";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../domain/frontend";
import { Box } from "@material-ui/core";
import Text from "../../../components/Text";

interface Props {}

const ForgottenPasswordCode: FC<Props> = (props: Props): JSX.Element => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useDefaultForm<ForgotPassSendCodeForm>(forgotPassSendCodeFormSchema);
  const [formError, setFormError] = useState("");

  const getForgotPasswordCode = handleSubmit(async ({ username }) => {
    try {
      await Auth.forgotPassword(username);

      history.push({
        pathname: ROUTES.forgottenPasswordSubmit,
        state: { username },
      });
    } catch (error) {
      setFormError(defaultErrorMessage);
    }
  });

  return (
    <UserManagementLayout heading={"Forgot password"}>
      <Box mb={5}>
        <Text size={1.7}>
          Please enter your email address below and we will send you a
          verification code to reset your password.
        </Text>
      </Box>
      <Form
        onSubmit={getForgotPasswordCode}
        isLoading={isSubmitting}
        error={formError}
        justifyButtons={"flex-end"}
        buttons={[{ type: "submit", text: "Submit", component: ButtonPrimary }]}
      >
        <FieldSet>
          <FieldWrapper name={"Email"} error={errors.username} required>
            <Input
              {...register("username")}
              autoComplete={"username"}
              placeholder={"Enter email address"}
            />
          </FieldWrapper>
        </FieldSet>
      </Form>
    </UserManagementLayout>
  );
};

export default ForgottenPasswordCode;
