import React from "react";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Router from "./Router";
import GlobalStyle from "./components/GlobalStyle";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import { useDialogueState } from "./state/dialogue";
import { Amplify } from "aws-amplify";
import config from "./config";
import SessionRehydrator from "./components/SessionRehydrator";
import ErrorBoundary from "./components/ErrorBoundary";
import InternalError from "./pages/public/internal-error";
import { useGlobalState } from "./state/global";
import { isMobile } from "react-device-detect";

Amplify.configure(config.amplify);

function App() {
  useGlobalState();
  useDialogueState();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        <ErrorBoundary fallback={InternalError}>
          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <SessionRehydrator>
              <Router />
            </SessionRehydrator>
          </DndProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
