import ky, { HTTPError, Options } from "ky";
import { Auth } from "aws-amplify";

class Client {
  private accessToken: string | undefined;

  private numFailedAuth: number;

  constructor() {
    this.numFailedAuth = 0;
  }

  public setAccessToken(accessToken: string): void {
    this.accessToken = accessToken;
  }

  public async fetch(url: string, option: Options): Promise<Response> {
    if (this.accessToken) {
      option.headers = {
        ...option.headers,
        Authorization: `Bearer ${this.accessToken}`,
      };
    }

    try {
      return await ky(url, option);
    } catch (error) {
      if (this.isAuthError(error)) {
        return this.tryToRefreshSession(error, url, option);
      }

      throw error;
    }
  }

  private async tryToRefreshSession(
    error: HTTPError,
    url: string,
    option: Options
  ): Promise<Response> {
    if (this.numFailedAuth++ >= 1) {
      throw error;
    }

    await Auth.currentSession();
    const response = await this.fetch(url, option);

    this.numFailedAuth = 0;
    return response;
  }

  private isAuthError(error: Error): boolean {
    return error instanceof HTTPError && error.response.status === 401;
  }
}

const httpClient = new Client();

export default httpClient;
